import useSWR from 'swr';
import api from 'services/api';
import { Sale, SaleFetchResponse } from 'models/sale';

interface FetchProps {
  page?: number;
  start?: string;
  end?: string;
  status?: string;
  tipo?: string;
  search?: string;
  segmento?: string;
  uf?: string;
  order?: string;
  orderType?: string;
  searchID?: string;
  tratavel?: string;
  lead_afiliado?: string;
  status_crosscore?: string;
  tratamento_portabilidade?: string;
  reprocessada?: boolean;
  reprocessada_manual?: boolean;
  documentacao_solicitada?: string;
  transporte_expresso?: boolean;
  erro_ordem?: boolean;
  engajamento?: boolean;
  ordenar_data_criacao?: string;
  ordenar_data_ativacao?: string;
  msisdn?: string;
  provisorio?: string;
  iccid?: string;
  rastreamento?: string;
  protocolo?: string;
  cpf?: string;
  nome?: string;
  filiacao?: string;
  email?: string;
  rg?: string;
  token?: string;
  utm_medium?: string;
  utm_campaign?: string;
  mensagem_erro?: string;
}

export const SalesFetch = ({
  page = 1,
  start,
  end,
  status,
  tipo,
  search,
  segmento,
  uf,
  order = 'vendas.id',
  orderType = 'DESC',
  searchID,
  tratavel,
  lead_afiliado,
  status_crosscore,
  tratamento_portabilidade,
  reprocessada,
  reprocessada_manual,
  documentacao_solicitada,
  transporte_expresso,
  erro_ordem,
  engajamento,
  ordenar_data_criacao,
  ordenar_data_ativacao,
  msisdn,
  provisorio,
  iccid,
  rastreamento,
  protocolo,
  cpf,
  nome,
  filiacao,
  email,
  rg,
  token,
  utm_medium,
  utm_campaign,
  mensagem_erro,
}: FetchProps) => {
  const queryParams: Record<string, string | number | boolean> = {
    page,
    order_by: order,
    order_type: orderType,
  };

  if (start && end) {
    queryParams.inicio = start;
    queryParams.fim = end;
  }

  if (search) queryParams.busca = search;
  if (status) queryParams.status = status;
  if (segmento) queryParams.segmento = segmento;
  if (tipo) queryParams.tipo = tipo;
  if (uf) queryParams.uf = uf;
  if (searchID) queryParams.id = parseInt(searchID, 10);
  if (tratavel) queryParams.tratavel = tratavel;
  if (lead_afiliado) queryParams.lead_afiliado = lead_afiliado;
  if (status_crosscore) queryParams.status_crosscore = status_crosscore;
  if (tratamento_portabilidade)
    queryParams.tratamento_portabilidade = tratamento_portabilidade;
  if (reprocessada) queryParams.trazer_reprocessados = 1;
  if (reprocessada_manual) queryParams.trazer_reprocessada_manual = 1;
  if (documentacao_solicitada)
    queryParams.documentacao_solicitada = documentacao_solicitada;
  if (transporte_expresso) queryParams.trazer_transporte_expresso = 1;
  if (erro_ordem) queryParams.trazer_erro_ordem = 1;
  if (engajamento) queryParams.trazer_engajamento = 1;
  if (ordenar_data_criacao)
    queryParams.ordenar_data_criacao = ordenar_data_criacao;
  if (ordenar_data_ativacao)
    queryParams.ordenar_data_ativacao = ordenar_data_ativacao;
  if (msisdn) queryParams.msisdn = msisdn;
  if (provisorio) queryParams.provisorio = provisorio;
  if (iccid) queryParams.iccid = iccid;
  if (rastreamento) queryParams.rastreamento = rastreamento;
  if (protocolo) queryParams.protocolo = protocolo;
  if (cpf) queryParams.cpf = cpf;
  if (nome) queryParams.nome = nome;
  if (filiacao) queryParams.filiacao = filiacao;
  if (email) queryParams.email = email;
  if (rg) queryParams.rg = rg;
  if (token) queryParams.token = token;
  if (utm_medium) queryParams.utm_medium = utm_medium;
  if (utm_campaign) queryParams.utm_campaign = utm_campaign;
  if (mensagem_erro) queryParams.mensagem_erro = mensagem_erro;

  const fetchKey = `sales_${Object.values(queryParams).join('_')}`;

  const { data, mutate } = useSWR<SaleFetchResponse>(fetchKey, async () => {
    const response = await api.get('/core/vendas', { params: queryParams });

    const paginationInfo = {
      currentPage: parseInt(response.headers['x-current-page'], 10),
      isEmpty: parseInt(response.headers['x-is-empty'], 10),
    };
    const sales = response.data as Sale[];

    return { sales, paginationInfo } as SaleFetchResponse;
  });

  return { data, mutate };
};
