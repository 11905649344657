import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/auth';

function DefineHomeRoute() {
  const { user } = useAuth();

  if (!user?.id) {
    return <Navigate to="/login" />;
  }
  return <Navigate to="/relatorio-gerencial" />;
}

export default DefineHomeRoute;
