import { useCallback, useEffect, useState } from 'react';
import api from 'services/api';
import { useEligibilityStore } from 'store/eligibilityStore';
import * as Yup from 'yup';
import { cpf as validateCPF } from 'util/validator';
import { format } from 'date-fns';
import { Erros, defaultErros, infoSchema } from '../infoSchemaYup';

export function useExecuteEligibility() {
  const [operation, setOperation] = useState('POS_PAGO');
  const [type, setType] = useState('NOVA_LINHA');
  const [method, setMethod] = useState('pmid');
  const [name, setName] = useState('');
  const [cpf, setCpf] = useState('');
  const [birthday, setBirthday] = useState('');
  const [filiation, setFiliation] = useState('');
  const [areaCode, setAreaCode] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [msisdn, setMsisdn] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingDefault, setLoadingDefault] = useState(false);
  const [isValidForm, setIsValidForm] = useState(false);
  const setEligibilityResponse = useEligibilityStore(
    state => state.setEligibilityResponse,
  );
  const [erros, setErros] = useState<Erros>(defaultErros);
  const [cpfIsValid, setCpfIsValid] = useState(false);
  const [disableForm, setDisabledForm] = useState(true);

  function clearForm() {
    setOperation('POS_PAGO');
    setType('NOVA_LINHA');
    setName('');
    setCpf('');
    setBirthday('');
    setFiliation('');
    setAreaCode('');
    setZipCode('');
    setMsisdn('');
    setEligibilityResponse('');
    setErros(defaultErros);
  }

  function executeEligibility(defaultData = false) {
    if (defaultData) {
      clearForm();
      setLoadingDefault(true);
    }
    !defaultData && setLoading(true);
    const body = {
      defaultData,
      customer: {
        cpf: cpf.replace(/\D/g, ''),
        birthday,
        name,
        filiation,
        zipCode,
      },
      msisdn,
      type,
      areaCode,
      operation,
    };

    api
      .post(`/core/elegibilidade/executar-elegibilidade?metodo=${method}`, body)
      .then(({ data }) => {
        setLoading(false);
        setLoadingDefault(false);
        setEligibilityResponse(JSON.stringify(data, undefined, 2));
      })
      .catch(error => {
        setLoading(false);
        setLoadingDefault(false);
        setEligibilityResponse(JSON.stringify(error.data, undefined, 4));
      });
  }

  const validateFormToExecute = () => {
    setErros(defaultErros);
    infoSchema
      .validate(
        {
          cpf,
          nascimento: birthday,
          cep: zipCode,
          msisdn,
        },
        {
          abortEarly: false,
        },
      )
      .then(async () => {
        executeEligibility();
      })
      .catch(err => {
        if (err instanceof Yup.ValidationError) {
          const newErros: Erros = { ...defaultErros };
          err.inner.forEach(error => {
            if (error.path) {
              newErros[error.path] = error.message;
            }
          });
          setErros(newErros);
        }
      });
  };

  const handlePersonalData = useCallback(() => {
    setDisabledForm(false);
    setBirthday('');
    setName('');
    setFiliation('');
  }, []);

  const handleFindByCPF = useCallback(
    (cpfValue: string) => {
      api
        .get(
          `/integrations/dados-pessoais-completo/${cpfValue.replace(
            /[^0-9]+/g,
            '',
          )}`,
        )
        .then(({ data }) => {
          const birthDayExist = format(
            new Date(data.data_nascimento),
            'dd/MM/yyyy',
          );
          setBirthday(birthDayExist);
          setName(data.nome_completo);
          setFiliation(data.nome_mae);

          setDisabledForm(false);
        })
        .catch(() => {
          handlePersonalData();
        });
    },
    [handlePersonalData],
  );

  useEffect(() => {
    const cleanedCPF = cpf.replace(/[^0-9]+/g, '');

    if (cleanedCPF.length === 0) {
      setDisabledForm(true);
      setCpfIsValid(false);
      handlePersonalData();
    } else if (cleanedCPF.length === 11) {
      if (validateCPF(cpf)) {
        handleFindByCPF(cpf);
        setCpfIsValid(false);
      } else {
        setCpfIsValid(true);
      }
    }
  }, [cpf, handleFindByCPF, handlePersonalData]);

  useEffect(() => {
    setIsValidForm(
      !!name &&
        !(cpf.length < 11) &&
        !!birthday &&
        !!filiation &&
        (!!areaCode || !!msisdn) &&
        !!zipCode,
    );
  }, [areaCode, birthday, cpf, filiation, msisdn, name, zipCode]);

  useEffect(() => {
    type === 'MIGRACAO' && setAreaCode('');
    type === 'NOVA_LINHA' && setMsisdn('');
  }, [areaCode, type, zipCode]);

  return {
    cpfIsValid,
    operation,
    setOperation,
    type,
    setType,
    method,
    setMethod,
    name,
    setName,
    cpf,
    setCpf,
    birthday,
    setBirthday,
    filiation,
    setFiliation,
    areaCode,
    setAreaCode,
    zipCode,
    setZipCode,
    msisdn,
    setMsisdn,
    loading,
    loadingDefault,
    isValidForm,
    erros,
    disableForm,
    executeEligibility,
    validateFormToExecute,
    clearForm,
  };
}
