import create from 'zustand';

type FlagFilterStore = {
  withReprocessed: boolean;
  setWithReprocessed(withReprocessed: boolean): void;

  withManuallyProcessed: boolean;
  setWithManuallyProcessed(withManuallyProcessed: boolean): void;

  withAffiliates: boolean;
  setWithAffiliates(withAffiliates: boolean): void;

  withTreatables: boolean;
  setWithTreatables(withTreatables: boolean): void;

  withPortabilityTreatment: boolean;
  setWithPortabilityTreatment(withPortabilityTreatment: boolean): void;

  withExpressShipping: boolean;
  setWithExpressShipping(withExpressShipping: boolean): void;

  withOrderError: boolean;
  setWithOrderError(withOrderError: boolean): void;

  withEngagement: boolean;
  setWithEngagement(withEngagement: boolean): void;
};

export const useFlagFilterStore = create<FlagFilterStore>(set => ({
  withReprocessed: false,
  setWithReprocessed(show: boolean) {
    set(state => ({ ...state, withReprocessed: show }));
  },

  withManuallyProcessed: false,
  setWithManuallyProcessed(show: boolean) {
    set(state => ({ ...state, withManuallyProcessed: show }));
  },

  withAffiliates: false,
  setWithAffiliates(show: boolean) {
    set(state => ({ ...state, withAffiliates: show }));
  },

  withTreatables: false,
  setWithTreatables(show: boolean) {
    set(state => ({ ...state, withTreatables: show }));
  },

  withPortabilityTreatment: false,
  setWithPortabilityTreatment(show: boolean) {
    set(state => ({ ...state, withPortabilityTreatment: show }));
  },

  withExpressShipping: false,
  setWithExpressShipping(show: boolean) {
    set(state => ({ ...state, withExpressShipping: show }));
  },

  withOrderError: false,
  setWithOrderError(show: boolean) {
    set(state => ({ ...state, withOrderError: show }));
  },

  withEngagement: false,
  setWithEngagement(show: boolean) {
    set(state => ({ ...state, withEngagement: show }));
  },
}));
