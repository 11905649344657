import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CardContent,
  CardHeader,
  DialogActions,
  TextField,
  Dialog,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import Default from 'assets/icons/benefitIcons/default.svg';
import benefit4GInternet from 'assets/icons/benefitIcons/benefit4GInternet.svg';
import { ButtonProgress } from 'components/StyledComponents';
import useBreakpoint from 'hooks/useBreakpoint';
import { AllQuantityOrComponentInBenefitsFetch } from 'fetches/benefitFetch';
import api from 'services/api';
import { useToast } from 'hooks/toast';
import { useBenefitStore } from 'store/benefitStore';
import { Benefit } from 'models/benefits';
import { TypeComponent, TypeQuantity } from 'pages/ManagerBenefits/types';
import { listaComponentes } from 'pages/ManagerBenefits/listComponents';
import { CardShowComponentImg } from './styles';
import Icons from '../icons';

interface EditBenefitProps {
  benefit: Benefit;
  closeDialog(): void;
}

function EditBenefit({ benefit, closeDialog }: EditBenefitProps) {
  const typeBenefit = useBenefitStore(state => state.type);
  const showBenefitEdit = useBenefitStore(state => state.showBenefitEdit);

  const { data: responseComponent } =
    AllQuantityOrComponentInBenefitsFetch('componente');
  const { data: responseQuantity } =
    AllQuantityOrComponentInBenefitsFetch('quantidade');

  const [nome, setNome] = useState(benefit?.descricao);
  const [tipo, setTipo] = useState(typeBenefit);
  const [hasAds, setHasAds] = useState(false);
  const [quantidade, setQuantidade] = useState(benefit?.quantidade);
  const [componente, setComponente] = useState(benefit?.componente);
  const [loading, setLoading] = useState(false);
  const [tipoQuantidade, setTipoQuantidade] = useState<TypeQuantity[]>([]);
  const [tipoComponente, setTipoComponente] = useState<TypeComponent[]>([]);

  const { addToast } = useToast();
  const { isOnBreakpoint: isTabletSizeOrSmaller } = useBreakpoint(1024);

  useEffect(() => {
    if (!responseComponent) {
      return;
    }
    if (!responseQuantity) {
      return;
    }
    setTipoComponente(responseComponent as TypeComponent[]);

    setTipoQuantidade(responseQuantity as TypeQuantity[]);
  }, [responseComponent, responseQuantity]);

  const clearForm = useCallback(() => {
    setNome('');
    setTipo('');
    setQuantidade('');
    setComponente('');
  }, []);

  const handleUpdate = useCallback(() => {
    const dataForm = {
      benefit_form: {
        descricao: nome,
        tipo,
        quantidade,
        componente,
        hasAds,
      },
    };
    setLoading(true);

    api
      .put(`/core/beneficios/${benefit?.id}`, dataForm)
      .then(() => {
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Editado com sucesso',
        });
        clearForm();
        closeDialog();
      })
      .catch(error => {
        addToast({
          type: 'error',
          title: 'Erro ao editar benefício',
          description: error?.data?.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    nome,
    tipo,
    quantidade,
    componente,
    benefit?.id,
    addToast,
    clearForm,
    closeDialog,
    hasAds,
  ]);

  return (
    <Dialog
      open={showBenefitEdit}
      onClose={() => closeDialog()}
      aria-labelledby={`edit-benefit_${benefit?.id}`}
      fullScreen={isTabletSizeOrSmaller}
    >
      <Box padding="20px">
        <CardHeader
          id={`edit-benefit_${benefit?.id}`}
          title="Anexar benefício"
          subheader="Preencha as informações do beneficio que deseja editar."
          style={{ padding: 10, paddingBottom: 19 }}
        />
        <CardContent>
          <TextField
            type="nome"
            id={nome}
            value={nome}
            aria-readonly
            fullWidth
            label="Informe o nome do benefício"
            margin="dense"
            name="nome"
            onChange={e => setNome(e.target.value)}
            variant="outlined"
            style={{ paddingBottom: 10 }}
            size="small"
          />

          <TextField
            label="Tipo"
            variant="outlined"
            select
            value={tipo}
            SelectProps={{ native: true }}
            onChange={e => setTipo(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            fullWidth
            style={{ paddingBottom: 20 }}
            disabled={loading}
          >
            <option value="" disabled>
              Selecione o tipo do beneficio
            </option>
            <option value="internet">internet</option>
            <option value="minutos">minutos</option>
            <option value="beneficios">beneficios</option>
            <option value="escolhas">escolhas</option>
          </TextField>

          <Box display="flex" alignItems="center" paddingBottom={2}>
            <FormControlLabel
              control={
                <Checkbox
                  value={hasAds}
                  onChange={e => setHasAds(e.target.checked)}
                  id="hasAds"
                  name="hasAds"
                  disabled={loading}
                />
              }
              label="Tem propagandas / *Sujeito à análise de crédito"
            />
          </Box>

          <TextField
            label="Quantidade"
            variant="outlined"
            value={quantidade}
            SelectProps={{ native: true }}
            onChange={e => setQuantidade(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            fullWidth
            style={{ paddingBottom: 20 }}
            disabled={loading}
          />
          <TextField
            label="Componente"
            variant="outlined"
            select
            value={componente}
            SelectProps={{ native: true }}
            onChange={e => setComponente(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            fullWidth
            style={{ paddingBottom: 20 }}
            disabled={loading}
          >
            <option value="">Sem componente</option>
            {listaComponentes
              ?.sort?.((a, b) => {
                if (!a || !b) return 0;

                return a.localeCompare(b);
              })
              ?.map?.(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
          </TextField>

          <CardShowComponentImg>
            <span> Visualização</span>
            {nome === 'Pacote de internet' ? (
              <img src={benefit4GInternet} alt="componente" />
            ) : (
              <img
                src={componente ? Icons[componente] : Default}
                alt="componente"
                height={24}
              />
            )}
          </CardShowComponentImg>
        </CardContent>
        <DialogActions
          style={{
            paddingTop: 14,
            justifyContent: 'space-between',
            paddingRight: 15,
            paddingLeft: 15,
          }}
        >
          <Button
            onClick={() => {
              closeDialog();
              clearForm();
            }}
            type="button"
            variant="contained"
            disabled={loading}
            style={{ backgroundColor: 'white', color: '#C92A2A' }}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => handleUpdate()}
            color="primary"
            type="button"
            variant="contained"
            disabled={loading}
          >
            Salvar
            {loading && <ButtonProgress size={24} />}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default EditBenefit;
