import create from 'zustand';

type ReportFilterFlagFetchStore = {
  startFetch: string;
  setStartFetch(startFetch: string): void;

  endFetch: string;
  setEndFetch(endFetch: string): void;

  dateStart: Date;
  setDateStart(dateStart: Date): void;

  dateEnd: Date;
  setDateEnd(dateEnd: Date): void;

  withReprocessedFetch: boolean;
  setWithReprocessedFetch(withReprocessedFetch: boolean): void;

  inReprocessingFetch: boolean;
  setInReprocessingFetch(inReprocessingFetch: boolean): void;

  withEngagementFetch: boolean;
  setWithEngagementFetch(withEngagementFetch: boolean): void;
};

export const useReportFilterFlagFetchStore = create<ReportFilterFlagFetchStore>(
  set => ({
    startFetch: '',
    setStartFetch(startFetch: string) {
      set(state => ({ ...state, startFetch }));
    },

    endFetch: '',
    setEndFetch(endFetch: string) {
      set(state => ({ ...state, endFetch }));
    },

    dateStart: new Date(),
    setDateStart(dateStart: Date) {
      set(state => ({ ...state, dateStart }));
    },

    dateEnd: new Date(),
    setDateEnd(dateEnd: Date) {
      set(state => ({ ...state, dateEnd }));
    },

    withReprocessedFetch: true,
    setWithReprocessedFetch(withReprocessedFetch: boolean) {
      set(state => ({ ...state, withReprocessedFetch }));
    },

    inReprocessingFetch: true,
    setInReprocessingFetch(inReprocessingFetch: boolean) {
      set(state => ({ ...state, inReprocessingFetch }));
    },

    withEngagementFetch: true,
    setWithEngagementFetch(withEngagementFetch: boolean) {
      set(state => ({ ...state, withEngagementFetch }));
    },
  }),
);
