import { CardContent, CardHeader } from '@mui/material';
import { Card, Header } from 'components/StyledComponents';
import { ReprocessingOfReachedCustomerBaseFetch } from 'fetches/reprocessingOfReachedCustomerBaseFetch';
import { useEffect, useState } from 'react';
import Pagination from 'components/Pagination';
import BaseImport from './components/BaseImport';
import EngagingList from './components/EngagingList';
import { DividerBase } from './components/EngagingList/components/BaseView/styles';
import Filters from './components/Filters';
import { importarBaseEngajamento } from '../../constants/permissions';
import { useAuth } from '../../hooks/auth';
import { BoxHeader, TopBar } from '../../components/CardList';
import useBreakpoint from '../../hooks/useBreakpoint';
import TotalConsults from './components/TotalConsults';
import QueueSize from './components/QueueSize';
import WorkersRunning from './components/WorkersRunning';

function Engaging() {
  const { hasPermission } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [statusFetch, setStatusFetch] = useState('');
  const [showImportBasePermission, setShowImportBasePermission] =
    useState(false);
  const { data: response, mutate } = ReprocessingOfReachedCustomerBaseFetch({
    page: currentPage,
    statusFetch,
  });
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  useEffect(() => {
    const shouldShow = hasPermission(importarBaseEngajamento);

    setShowImportBasePermission(shouldShow);
  }, [hasPermission]);

  return (
    <Card>
      <TopBar isDesktop={!isTableOrLess}>
        <CardHeader
          title="Engajador"
          subheader="Painel de reprocessamento de vendas em busca de engajamento de clientes elegíveis."
        />
        <BoxHeader isDesktop={!isTableOrLess}>
          <TotalConsults isDesktop={!isTableOrLess} />
          <WorkersRunning
            isDesktop={!isTableOrLess}
            processName="process-reach"
          />
          <QueueSize
            isDesktop={!isTableOrLess}
            connection="sqs"
            queue="process-reach"
          />
        </BoxHeader>
      </TopBar>
      {showImportBasePermission && (
        <>
          <BaseImport updateReprocessing={mutate} />
          <DividerBase />
        </>
      )}

      <Header>
        <CardHeader
          title="Reprocessamentos"
          style={{ padding: '0px 0px 0px 16px' }}
        />

        <Filters setStatusFetch={setStatusFetch} />
      </Header>

      <CardContent>
        <EngagingList basesReprocessed={response?.basesReprocessed} />

        {response && (
          <Pagination
            currentPage={currentPage}
            lastPage={response?.paginationInfo.lastPage}
            perPage={response?.paginationInfo.perPage}
            total={response?.paginationInfo.total}
            setCurrentPage={setCurrentPage}
          />
        )}
      </CardContent>
    </Card>
  );
}

export default Engaging;
