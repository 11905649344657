import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CardContent,
  CardHeader,
  DialogActions,
  TextField,
  Dialog,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { ButtonProgress } from 'components/StyledComponents';
import useBreakpoint from 'hooks/useBreakpoint';
import { Add } from '@mui/icons-material';
import palette from 'theme/palette';
import Default from 'assets/icons/benefitIcons/default.svg';
import benefit4GInternet from 'assets/icons/benefitIcons/benefit4GInternet.svg';
import { AllQuantityOrComponentInBenefitsFetch } from 'fetches/benefitFetch';
import api from 'services/api';
import { useToast } from 'hooks/toast';
import { PlanByID } from 'models/planById';
import { TypeComponent, TypeQuantity } from '../types';
import { CardShowComponentImg } from '../BenefitsList/EditBenefit/styles';
import Icons from '../BenefitsList/icons';
import { listaComponentes } from '../listComponents';

interface AttachBenefitFormProps {
  plan_id?: number;
  showAttachBenefit: boolean;
  setShowAttachBenefit(showAttachBenefit: boolean): void;
  closeAttachDialog(): void;
  mutatePlan?(plan: PlanByID, shouldRevalidate?: boolean): void;
}

function AttachBenefit({
  plan_id,
  showAttachBenefit,
  setShowAttachBenefit,
  closeAttachDialog,
  mutatePlan,
}: AttachBenefitFormProps) {
  const { data: responseComponent } =
    AllQuantityOrComponentInBenefitsFetch('componente');
  const { data: responseQuantity } =
    AllQuantityOrComponentInBenefitsFetch('quantidade');

  const [nome, setNome] = useState('');
  const [tipo, setTipo] = useState('');
  const [hasAds, setHasAds] = useState(false);
  const [quantidade, setQuantidade] = useState('');
  const [componente, setComponente] = useState('');
  const [loading, setLoading] = useState(false);
  const [tipoQuantidade, setTipoQuantidade] = useState<TypeQuantity[]>([]);
  const [tipoComponente, setTipoComponente] = useState<TypeComponent[]>([]);

  const { addToast } = useToast();
  const { isOnBreakpoint: isTabletSizeOrSmaller } = useBreakpoint(1024);

  useEffect(() => {
    if (!responseComponent) {
      return;
    }
    if (!responseQuantity) {
      return;
    }
    setTipoComponente(responseComponent as TypeComponent[]);

    setTipoQuantidade(responseQuantity as TypeQuantity[]);
  }, [responseComponent, responseQuantity]);

  const clearForm = useCallback(() => {
    setNome('');
    setTipo('');
    setQuantidade('');
    setComponente('');
  }, []);

  const handleSave = useCallback(() => {
    const data = {
      benefit_form: {
        descricao: nome,
        tipo,
        quantidade,
        componente,
        hasAds,
      },
    };
    setLoading(true);

    api
      .post(`/core/beneficios`, data)
      .then(() => {
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Anexado com sucesso',
        });

        closeAttachDialog();
        clearForm();
      })
      .catch(error => {
        addToast({
          type: 'error',
          title: 'Erro ao anexar benefício',
          description: error?.data?.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    nome,
    tipo,
    quantidade,
    componente,
    hasAds,
    addToast,
    closeAttachDialog,
    clearForm,
  ]);

  const handleSaveBenefitPlan = useCallback(() => {
    const data = {
      descricao: nome,
      tipo,
      quantidade,
      componente,
    };
    setLoading(true);

    api
      .post(`core/planos/${plan_id}/adicionar-beneficio`, data)
      .then(({ data: benefit }) => {
        mutatePlan && mutatePlan(benefit, true);
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Anexado com sucesso',
        });

        closeAttachDialog();
        clearForm();
      })
      .catch(error => {
        addToast({
          type: 'error',
          title: 'Erro ao anexar benefício',
          description: error?.data?.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    nome,
    tipo,
    quantidade,
    componente,
    plan_id,
    mutatePlan,
    addToast,
    closeAttachDialog,
    clearForm,
  ]);
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setShowAttachBenefit(true)}
      >
        {isTabletSizeOrSmaller ? (
          <Add style={{ color: palette.white }} />
        ) : (
          'Anexar beneficio'
        )}
      </Button>

      <Dialog
        open={showAttachBenefit}
        onClose={() => closeAttachDialog()}
        aria-labelledby="form-dialog-title"
        fullScreen={isTabletSizeOrSmaller}
      >
        <Box padding="20px">
          <CardHeader
            title="Anexar benefício"
            subheader="Preencha as informações do novo beneficio que deseja anexar."
            style={{ padding: 10, paddingBottom: 19 }}
          />
          <CardContent>
            <TextField
              type="nome"
              id={nome}
              value={nome}
              aria-readonly
              fullWidth
              label="Informe o nome do benefício"
              margin="dense"
              name="nome"
              onChange={e => setNome(e.target.value)}
              variant="outlined"
              style={{ paddingBottom: 10 }}
              size="small"
            />

            <TextField
              label="Tipo"
              variant="outlined"
              select
              value={tipo}
              SelectProps={{ native: true }}
              onChange={e => setTipo(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              fullWidth
              style={{ paddingBottom: 20 }}
              disabled={loading}
            >
              <option value="" disabled>
                Selecione o tipo do beneficio
              </option>
              <option value="internet">internet</option>
              <option value="minutos">minutos</option>
              <option value="beneficios">beneficios</option>
              <option value="escolhas">escolhas</option>
            </TextField>

            <Box display="flex" alignItems="center" paddingBottom={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={hasAds}
                    onChange={e => setHasAds(e.target.checked)}
                    id="hasAds"
                    name="hasAds"
                    disabled={loading}
                  />
                }
                label="Tem propagandas"
              />
            </Box>

            <TextField
              label="Quantidade"
              variant="outlined"
              select
              value={quantidade}
              SelectProps={{ native: true }}
              onChange={e => setQuantidade(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              fullWidth
              style={{ paddingBottom: 20 }}
              disabled={loading}
            >
              <option value="">Sem quantidade</option>
              {tipoQuantidade &&
                tipoQuantidade.map(option => (
                  <option key={option.quantidade} value={option.quantidade}>
                    {option.quantidade}
                  </option>
                ))}
            </TextField>
            <TextField
              label="Componente"
              variant="outlined"
              select
              value={componente}
              SelectProps={{ native: true }}
              onChange={e => setComponente(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              fullWidth
              style={{ paddingBottom: 20 }}
              disabled={loading}
            >
              <option value="">Sem componente</option>
              {listaComponentes
                ?.sort?.((a, b) => {
                  if (!a || !b) return 0;

                  return a.localeCompare(b);
                })
                ?.map?.(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
            </TextField>

            <CardShowComponentImg>
              <span> Visualização</span>
              {nome === 'Pacote de internet' ? (
                <img src={benefit4GInternet} alt="componente" />
              ) : (
                <img
                  src={componente ? Icons[componente] : Default}
                  alt="componente"
                  height={24}
                />
              )}
            </CardShowComponentImg>
          </CardContent>
          <DialogActions
            style={{
              paddingTop: 14,
              justifyContent: 'space-between',
              paddingRight: 15,
              paddingLeft: 15,
            }}
          >
            <Button
              onClick={() => {
                closeAttachDialog();
                clearForm();
              }}
              type="button"
              variant="contained"
              disabled={loading}
              style={{ backgroundColor: 'white', color: '#C92A2A' }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => {
                plan_id ? handleSaveBenefitPlan() : handleSave();
              }}
              color="primary"
              type="button"
              variant="contained"
              disabled={loading}
            >
              Salvar
              {loading && <ButtonProgress size={24} />}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

export default AttachBenefit;
