import { MonthSelected } from 'pages/ManagementReport/Filters/PopoverActions';
import create from 'zustand';

type ReportFilterStore = {
  localToday: boolean;
  setLocalToday(localToday: boolean): void;

  localYesterday: boolean;
  setLocalYesterday(localYesterday: boolean): void;

  localLastWeek: boolean;
  setLocalLastWeek(localLastWeek: boolean): void;

  localWithReprocessed: boolean;
  setLocalWithReprocessed(localWithReprocessed: boolean): void;

  localWithInReprocessing: boolean;
  setLocalWithInReprocessing(localWithInReprocessing: boolean): void;

  localWithEngagement: boolean;
  setLocalWithEngagement(localWithEngagement: boolean): void;

  localDateStart: Date;
  setLocalDateStart(localDateStart: Date): void;

  localDateEnd: Date;
  setLocalDateEnd(localDateEnd: Date): void;

  monthsSelected: MonthSelected;
  setMonthsSelected(value: MonthSelected): void;
};

export const useReportFilterStore = create<ReportFilterStore>(set => ({
  localToday: true,
  setLocalToday: localToday => set(() => ({ localToday })),

  localYesterday: false,
  setLocalYesterday: localYesterday => set(() => ({ localYesterday })),

  localLastWeek: false,
  setLocalLastWeek: localLastWeek => set(() => ({ localLastWeek })),

  localWithReprocessed: true,
  setLocalWithReprocessed: localWithReprocessed =>
    set(() => ({ localWithReprocessed })),

  localWithInReprocessing: true,
  setLocalWithInReprocessing: localWithInReprocessing =>
    set(() => ({ localWithInReprocessing })),

  localWithEngagement: true,
  setLocalWithEngagement: localWithEngagement =>
    set(() => ({ localWithEngagement })),

  localDateStart: new Date(),
  setLocalDateStart: localDateStart => set(() => ({ localDateStart })),

  localDateEnd: new Date(),
  setLocalDateEnd: localDateEnd => set(() => ({ localDateEnd })),

  monthsSelected: {},
  setMonthsSelected: (value: MonthSelected) => set({ monthsSelected: value }),
}));
